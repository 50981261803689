.secondheading-sec {
  padding: 30px 20px;
  .inner {
    display: flex;
    flex-direction: column;
    row-gap: 21px;
    margin: 0px auto;
    width: 100%;
    max-width: 916px;
    h1 {
      text-align: center;
      line-height: 100%;
      position: relative;
      .spinny-words {
        display: inline-block;
        text-align: left;
        position: relative;
        min-height: 70px;
      }
      .spinny-words span {
        position: absolute;
        font-weight: bold;
        top: -0px;
        opacity: 0;
        // animation: rotateWord 8s linear infinite;
      }

      @keyframes rotateWord {
        0% {
          opacity: 0;
        }
        2% {
          opacity: 0;
          transform: translateY(-30px);
        }
        5% {
          opacity: 1;
          transform: translateY(0px);
        }
        17% {
          opacity: 1;
          transform: translateY(0px);
        }
        20% {
          opacity: 0;
          transform: translateY(30px);
        }
        80% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }
    .medium {
      width: 100%;
      text-align: center;
    }
    .text-yellow {
      color: var(--main-color);
    }
    .text-black {
      color: black;
    }
  }
}

.headingWidth {
  // width: 100%;
  margin: 0 auto;
  //border: 2px solid red;
}

@media screen and (max-width: 1750px) {
  .secondheading-sec {
    .inner {
      h1 {
        .spinny-words {
          min-height: 55px;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .secondheading-sec {
    .inner {
      row-gap: 10px;
      max-width: 770px;
      h1 {
        .spinny-words {
          min-height: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .secondheading-sec {
    padding: 20px 20px 10px 20px;
    .inner {
      row-gap: 5px;
      h1 {
        .spinny-words {
          min-height: 30px;
        }
      }
    }
  }
}
