.simplifyWrapper {
  padding: 20px;
  // border: 2px solid red;
  margin: 0 auto;
  position: relative;
  .leftGlow{
position: absolute;
left: 0;
z-index: -1;
top: -40px;
  }
  .rightGlow{
    position: absolute;
    right: 0;
    z-index: -1;
    top: -350px;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    max-width: 1500px;
    margin: 0 auto;
    // border: 2px solid red;
    .leftArea {
      width: 50%;
      //border: 2px solid green;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 40px;

      .checkBoxWrappper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        // border: 1px solid black;
        li {
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
        }
      }
    }
    .rightArea {
      width: 50%;
      //  border: 2px solid blue;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .simplifyWrapper {
    .leftGlow{
      display: none;
    }
    .rightGlow{
      display: none;
    }
    .inner {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .leftArea {
        width: 100%;
        gap: 23px;
        .checkBoxWrappper {
          grid-template-columns: repeat(2, 1fr);
          li{
            img{
              width: 20px;
            }
          }
        }
      }
      .rightArea {
        width: 100%;
      }
    }
  }
}
