.roboSectionWrapper {
  padding: 20px;
  position: relative;
  margin-bottom: 170px;
// border: 1px solid black;

  margin-top: 230px;

  
  &::before {
    position: absolute;
    content: url("../../assets//images//aiSummaros//backLayerYellow.png");
    top: -530px;
    right: 0;
    z-index: -1;
  }
  .glow{
    position: absolute;
    right: 0;
    top: -500px;
    z-index: -1;


  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1500px;
    margin: 0 auto;
    .leftArea {
      width: 58%;
     // border: 1px solid red;
      padding-bottom: 100px;
      margin-top: 100px;
      p {
        max-width: 720px;
      }

      .totalNum {
        padding-top: 200px;
        // h1 {
        //   line-height: 79.71px;
        //   letter-spacing: -1px;
        // }
      }
    }
    .rightArea {
      width: 40%;
     // border: 1px solid purple;
      position: relative;
      height: 700px;

      .robo {
        position: absolute;
        top: -130px;
        right: 130px;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .roboSectionWrapper {
    margin-top: 135px;
    margin-bottom: 50px;
    .inner {
      gap: 20px;
      .rightArea {
        .robo {
          top: -70px;
          right: 44px;
          width: 582px;
          height: 779px;
        }
      }
      .leftArea {
        p {
          max-width: 600px;
        }
        .totalNum {
          padding-top: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 1250px) {
  .roboSectionWrapper {
    .inner {
      .rightArea {
        .robo {
          top: -70px;
          right: 18px;
          width: 500px;
          height: 723px;
        }
      }
      .leftArea p {
        max-width: 470px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .roboSectionWrapper {
margin-top: 0px;
margin-bottom: 0px;
    &::before{
      display: none;
    }
    .glow{
      display: none;
    }
    .inner {
      flex-direction: column;
      gap: 40px;
      .leftArea {
        width: 100%;
        margin-top: 0;
        padding-bottom: 0px;
        .totalNum{
          padding-top: 0px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px !important;
          
          .task{
            text-align: center;
          }
        }
      }
      .rightArea {
        width: 100%;
        height: 400px;

        .robo {
          top: 50%;
          left: 50%;
          width: 300px;
          height: 400px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
