.cardWrapper {
  //width: 700px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  // border-right: 1px solid #dfdfdf;
  // border-bottom: 1px solid #dfdfdf;
  background: transparent;
  position: relative;
  z-index: 2;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    transition: all 1s ease-in-out;

    &:hover {
      transform: translateY(-50px);
    }
    .border-left {
      border-left: 1px solid #dfdfdf;
    }
    p {
      //max-width: 426px;
      max-width: 382px;
      margin: 0 auto;
      text-align: center;
      color: var(--black-color);
    }
  }
}
.border1 {
  border-right: 1px solid #dfdfdf;
}
.border-bottom {
  border-bottom: 1px solid #dfdfdf !important;
}

@media screen and (max-width: 1200px) {
  .cardWrapper {
    padding: 20px;
    .inner {
      h6 {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .cardWrapper {
    height: auto;
    .inner {
      transition: none;

      &:hover {
        transform: none;
      }
      h6 {
        text-align: center;
      }
      img {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
  .border1 {
    border-right: none;
  }
}
