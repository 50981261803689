.blogs-sec {
  .inner {
    max-width: 1730px;
    margin: 0px auto;
    padding: 100px;
    display: flex;
    flex-direction: column;
    row-gap: 70px;
    .head {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .right {
        padding-top: 25px;
        border-top: 1.779px solid #ffdd80;
        display: flex;
        align-items: center;
        span {
          padding: 0px 26px;
          cursor: pointer;
        }
        .active {
          position: relative;
          &::before {
            position: absolute;
            left: 50%;
            top: -28px;
            transform: translate(-50%);
            content: "";
            width: 100%;
            height: 3.558px;
            border-radius: 4.448px;

            background: var(--main-color);
          }
        }
      }
    }
    .blog-cards {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 26px;
      .blog-card {
        display: flex;
        flex-direction: column;
        row-gap: 13px;
        .medium {
          color: var(--black-color);
        }
        img {
          width: 100%;
          border-radius: 0px 53.372px;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .blogs-sec {
    .inner {
      padding: 80px;
      row-gap: 50px;
      .head {
        .right {
          padding-top: 20px;
          span {
            padding: 0px 20px;
          }
          .active {
            &::before {
              top: -22px;
            }
          }
        }
      }
      .blog-cards {
        column-gap: 20px;
        .blog-card {
          row-gap: 10px;

          img {
            border-radius: 0px 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .blogs-sec {
    .inner {
      padding: 80px;
      row-gap: 50px;
      .head {
        .right {
          padding-top: 20px;
          span {
            padding: 0px 20px;
          }
          .active {
            &::before {
              top: -22px;
            }
          }
        }
      }
      .blog-cards {
        column-gap: 20px;
        grid-template-columns: repeat(3, 1fr);
        .blog-card {
          row-gap: 10px;
          &:last-child {
            display: none;
          }
          img {
            border-radius: 0px 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .blogs-sec {
    .inner {
      padding: 30px 20px;
      row-gap: 20px;
      .head {
        flex-direction: column;
        row-gap: 10px;
        .right {
          margin-right: auto;
          padding-top: 20px;
          span {
            padding: 0px 10px;
          }
          .active {
            &::before {
              top: -22px;
            }
          }
        }
      }
      .blog-cards {
        row-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
        .blog-card {
          row-gap: 5px;
          max-width: 350px;
          margin: 0px auto;
          &:last-child {
            display: none;
          }
          img {
            border-radius: 0px 40px;
          }
        }
      }
    }
  }
}
