.about-sec {
  max-width: 1616px;
  margin: 0px auto;
  padding: 0px 100px 70px 100px;
  display: flex;
  flex-direction: column;
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 120px;
    .left {
      width: 50%;
      position: relative;

      .backcolor {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 702px;
        opacity: 0.2;
        background: #ff3d00;
        filter: blur(233.26466369628906px);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 2;
        max-height: 702px;
      }
    }
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 26px;
      p {
        max-width: 500px;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .about-sec {
    padding: 0px 80px 60px 80px;

    .inner {
      column-gap: 60px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .about-sec {
    padding: 0px 80px 60px 80px;
    .inner {
      column-gap: 40px;
      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        p {
          max-width: 500px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .about-sec {
    width: 100%;
    padding: 0px 20px 30px 20px;

    .inner {
      flex-direction: column;
      column-gap: 20px;
      .left {
        width: 100%;
        max-width: 350px;
      }
      .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;
        p {
          max-width: 500px;
          text-align: center;
        }
      }
    }
  }
}
