.hero-sec {
  padding: 260px 257px 170px 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 70px;
  position: relative;
  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    p {
      max-width: 637px;
    }
    .buttons {
      display: flex;
      align-items: center;
      column-gap: 50px;
    }
  }
  .right {
    width: 50%;
    position: relative;
    .heromain-img {
      width: 100%;
    }
    .heroai-img {
      position: absolute;
      top: -33%;
      right: -19%;
      animation: heroimg 3s ease-in-out infinite;
    }
    @keyframes heroimg {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(50px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
  .social-links {
    position: absolute;
    bottom: 53px;
    left: 110px;
    column-gap: 32px;
    display: flex;
    a {
      img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .hero-sec {
    padding: 260px 150px 150px 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 70px;

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      p {
        max-width: 637px;
      }
      .buttons {
        display: flex;
        align-items: center;
        column-gap: 40px;
      }
    }
    .right {
      width: 50%;
      position: relative;
      .heromain-img {
        width: 100%;
      }
      .heroai-img {
        width: 350px;
        height: 600px;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .hero-sec {
    padding: 200px 80px 150px 80px;
    column-gap: 50px;
    .large-btn {
      width: 160px !important;
      height: 50px !important;

      font-size: 18px;
    }
    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      .buttons {
        column-gap: 20px;
      }
    }
    .right {
      width: 50%;
      position: relative;
      .heromain-img {
        width: 100%;
      }
      .heroai-img {
        width: 300px;
        height: 500px;
        object-fit: cover;
        top: -25%;
      }
      @keyframes heroimg {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(30px);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
    .social-links {
      position: absolute;
      column-gap: 20px;
      left: 80px;
      a {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .hero-sec {
    padding: 100px 20px 70px 20px;
    flex-direction: column;
    row-gap: 40px;
    .large-btn {
      width: 140px !important;
      height: 40px !important;

      font-size: 16px;
    }
    .left {
      width: 100%;
      row-gap: 10px;

      .buttons {
        column-gap: 20px;
      }
    }
    .right {
      width: 100%;
      position: relative;
      display: flex;
      .heromain-img {
        width: 100%;
        max-width: 350px;
        margin: 0px auto;
      }
      .heroai-img {
        display: none;
      }
    }
    .social-links {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%);
      column-gap: 15px;
      display: flex;
      a {
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }
  }
}

.videoModal {
  // width: 70%;
  // margin: 0 auto;

  // border: 2px solid purple;
  video {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
}
:where(.css-dev-only-do-not-override-djtmh8).ant-modal .ant-modal-content {
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}
