.primary-btn {
  background: var(--main-color);
  color: var(--black-color);
  font-family: "Roboto";
  font-style: normal;
  line-height: normal;
  position: relative;
  border: 2px solid var(--main-color);
}
.large-btn {
  font-size: 22px;
}
.small-btn {
  font-size: 17px;
}
.secondary-btn {
  border: 2px solid var(--main-color);
  color: var(--main-color);
  font-family: "Roboto";
  font-style: normal;
  line-height: normal;
  background: transparent;
}
.primary-btn::after {
  z-index: 2;
  content: "";
  background: var(--main-color);
  border-radius: 63px;
  opacity: 0.6;
  filter: blur(9.050000190734863px);
  position: absolute;
  width: 88.89%;
  height: 24px;
  left: 50%;
  right: 50%;
  bottom: -10px;
  transform: translate(-50%);
}
button {
  cursor: pointer;
}
@media screen and (max-width: 1500px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 600px) {
  // .primary-btn {
  //   background: var(--main-color);
  //   color: var(--black-color);
  //   font-family: "Roboto";
  //   font-style: normal;
  //   line-height: normal;
  //   position: relative;
  //   border: 2px solid var(--main-color);
  // }
  // .large-btn {
  //   font-size: 18px;
  // }
  .small-btn {
    font-size: 14px;
    width: 140px !important;
    height: 40px !important;
  }
  // .secondary-btn {
  //   border: 2px solid var(--main-color);
  //   color: var(--main-color);
  //   font-family: "Roboto";
  //   font-style: normal;
  //   line-height: normal;
  //   background: transparent;
  // }
  .primary-btn::after {
    height: 10px;

    bottom: -5px;
    transform: translate(-50%);
  }
}
