.blogpage-sec {
  padding: 233px 80px 220px 80px;
  .inner {
    max-width: 1342px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 130px;
    .head-img {
      max-width: 789px;
      max-height: 324px;
      width: 100%;
    }
    .content {
      display: flex;
      flex-direction: column;
      row-gap: 50px;
      .ist {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        .blog-main {
          width: 100%;
          max-height: 686px;
          border-radius: 21px;
          object-fit: cover;
        }
        h6 {
          color: var(--black-color);
        }
      }
      .second {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        h3 {
          color: var(--black-color);
        }
        .paras {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
        }
      }
      .third {
        display: flex;
        align-items: flex-start;
        column-gap: 98px;
        .left {
          width: 54%;

          img {
            border-radius: 58px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .right {
          width: 46%;
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          h3 {
            color: var(--black-color);
          }
        }
      }
      .forth {
        display: flex;
        align-items: flex-start;
        column-gap: 98px;
        .right {
          width: 54%;

          img {
            border-radius: 58px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .left {
          width: 46%;
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          h3 {
            color: var(--black-color);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .blogpage-sec {
    padding: 150px 80px 100px 80px;
    .inner {
      row-gap: 60px;
      .content {
        row-gap: 30px;
        .ist {
          row-gap: 20px;
        }
        .second {
          row-gap: 20px;
          .paras {
            row-gap: 15px;
          }
        }
        .third {
          column-gap: 40px;
          .left {
            width: 50%;
          }
          .right {
            width: 50%;
            row-gap: 10px;
          }
        }
        .forth {
          column-gap: 40px;
          .right {
            width: 50%;
          }
          .left {
            width: 50%;
            row-gap: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .blogpage-sec {
    padding: 150px 80px 80px 80px;
    .inner {
      row-gap: 30px;
      .head-img {
        max-width: 500px;
      }
      .content {
        row-gap: 30px;
        .ist {
          row-gap: 20px;
        }
        .second {
          row-gap: 10px;
          .paras {
            row-gap: 10px;
          }
        }
        .third {
          column-gap: 20px;
          align-items: center;
          .right {
            row-gap: 5px;
          }
        }
        .forth {
          column-gap: 40px;
          align-items: center;
          .right {
            width: 50%;
          }
          .left {
            width: 50%;
            row-gap: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .blogpage-sec {
    padding: 100px 20px 30px 20px;
    .inner {
      row-gap: 10px;
      h3 {
        line-height: 110%;
      }
      .head-img {
        max-width: 350px;
        width: 100%;
      }
      .content {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .ist {
          row-gap: 10px;
          .blog-main {
            border-radius: 15px;
          }
        }
        .second {
          row-gap: 10px;

          .paras {
            row-gap: 10px;
          }
        }
        .third {
          flex-direction: column;
          row-gap: 10px;
          .left {
            width: 100%;

            img {
              border-radius: 30px;
              max-height: 250px;
            }
          }
          .right {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
          }
        }
        .forth {
          flex-direction: column-reverse;
          row-gap: 10px;
          .right {
            width: 100%;

            img {
              border-radius: 30px;
              max-height: 250px;
            }
          }
          .left {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
          }
        }
      }
    }
  }
}
