.helpWrapper {
  padding: 20px;
  position: relative;
  .Typewriter {
    width: 1000px !important;
    font-size: 15px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  // border: 2px solid red;
  // padding-top: 35px;
  // padding-bottom: 100px;
  // border: 1px solid red;
  //margin-top: 300px;

  .leftGlow {
    position: absolute;
    z-index: -1;
    top: -500px;
    left: 0;
  }
  .rightGlow {
    position: absolute;
    top: -500px;
    right: 0;
  }

  .inner {
    max-width: 1051px;
    margin: 0 auto;
    .midDiv {
      max-width: 1051px;
      position: relative;
      margin: 0 auto;
      padding: 0px 30px;
      padding-bottom: 52px;
      p {
        padding-left: 63px;
      }

      .grayLines {
        position: absolute;
        top: 0px;
        left: 22%;
        z-index: -1;
        //  border: 1px solid green;
      }

      .inputBox {
        padding: 12px 14px;
        max-width: 1027px;
        margin: 0 auto;
        border-radius: 21px;
        background-color: var(--white);
        border: 11px solid rgba(0, 0, 0, 0.2);

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 7px 7px 15px;
        // border: 1px solid purple;
        margin-top: 6px;
        input {
          width: 100%;
          background-color: var(--white);
          border: none;
          outline: none;
        }
        button {
          padding: 18px 20px 18px 40px;
          border-radius: 10px;
          background: linear-gradient(180deg, #f0b006 -5.8%, #ff9b05 99.76%);
          color: var(--white);
          //text-align: center;
          font-family: "Montserrat";
          font-size: 15.689px;

          font-style: normal;
          font-weight: 700;
          line-height: normal;
          position: relative;
          border: none;

          &::before {
            content: url("../../../src/assets/images/aiSummaros/btnSvg.svg");
            position: absolute;
            left: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .helpWrapper {
    padding-bottom: 0px 0px 20px 0px;
    .typewriter {
      display: none !important;
    }
    .rightGlow {
      display: none;
    }
    .leftGlow {
      display: none;
    }
    .inner {
      .midDiv {
        padding: 0px;
        border-radius: 15px;
        p {
          padding-top: 0 !important;
          padding-left: 20px;
        }
        .grayLines {
          display: none;
        }
        .inputBox {
          border: 6px solid rgba(0, 0, 0, 0.2);
          border-radius: 15px;
          input {
            font-size: 15px;
            &::placeholder {
              display: block;
            }
          }
          button {
            padding: 15px 15px 15px 40px;
            font-size: 13px;
          }
        }
      }
    }
  }
}
