.trusted-sec {
  padding: 50px 100px 150px 100px;
  position: relative;
  .inner {
    max-width: 1450px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 13px;
    button {
      &::after {
        display: none;
      }
    }
    h2 {
      line-height: 127%;
    }
    p {
      max-width: 724px;
      text-align: center;
      margin-bottom: 76px;
      color: var(--lightblack);
    }
    img {
      width: 100%;
      max-height: 575.997px;
      object-fit: cover;
    }
  }
  .back-layer {
    position: absolute;
    width: 838px;
    height: 924px;
    border-radius: 924px;
    opacity: 0.05;
    background: #fb0;
    filter: blur(172.85000610351562px);
    bottom: -160px;
    z-index: 2;
  }
}
@media screen and (max-width: 1500px) {
  .trusted-sec {
    padding: 50px 80px;
    .inner {
      button {
        font-size: 18px;
      }
      p {
        margin-bottom: 40px;
      }
    }
    .back-layer {
      position: absolute;
      width: 538px;
      height: 624px;
      border-radius: 624px;
    }
  }
}

@media screen and (max-width: 600px) {
  .trusted-sec {
    padding: 30px 20px;
    .inner {
      row-gap: 10px;
      button {
        width: 190px !important;
        height: 40px !important;
        font-size: 16px;
      }
      h2 {
        line-height: 100%;
      }
      p {
        margin-bottom: 20px;
      }
    }
    .back-layer {
      display: none;
    }
  }
}
