.wesay-sec {
  display: flex;
  flex-direction: column;
  padding: 100px;
  .heading-img {
    width: 58.591px;
    height: 69.944px;
    object-fit: cover;
    margin: 0px auto;
    margin-bottom: 19px;
  }
  .ist-color {
    &:hover {
      background: linear-gradient(90deg, #f24e9d 0%, #f15f79 100%);
      box-shadow: 0px 0px 25px 2px #f15f79 !important;
      color: var(--white);
    }
  }
  .second-color {
    &:hover {
      background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
      box-shadow: 0px 0px 26.34px 2.107px #00f5a0 !important;
      color: var(--white);
    }
  }
  .third-color {
    &:hover {
      background: linear-gradient(90deg, #fd746c 0%, #ff9068 100%);
      box-shadow: 0px 0px 27.889px 2.231px #fd746c !important;
      color: var(--white);
    }
  }
  .forth-color {
    &:hover {
      background: linear-gradient(90deg, #8e2de2 0%, #db5efb 100%);
      box-shadow: 0px 0px 25px 2px #e024ff !important;
      color: var(--white);
    }
  }
  .fifth-color {
    &:hover {
      background: linear-gradient(90deg, #ffb347 0%, #fc3 100%);
      box-shadow: 0px 0px 27.889px 2.231px #fc3 !important;
      color: var(--white);
    }
  }
  .sixth-color {
    &:hover {
      background: linear-gradient(90deg, #e22ddb 0%, #4a00e0 100%);
      box-shadow: 0px 0px 26.34px 2.107px #b947ff !important;
      color: var(--white);
    }
  }
  .seventh-color {
    &:hover {
      background: linear-gradient(90deg, #f0c 0%, #ce062a 100%);
      box-shadow: 0px 0px 27.889px 2.231px #ff24c2 !important;
      color: var(--white);
    }
  }
  .eighth-color {
    &:hover {
      background: linear-gradient(90deg, #f85032 0%, #e73827 100%);
      box-shadow: 0px 0px 25px 2px #f15f79 !important;
      color: var(--white);
    }
  }
  .nineth-color {
    &:hover {
      background: linear-gradient(90deg, #00d2ff 0%, #3a7bd5 100%);
      box-shadow: 0px 0px 27.889px 2.231px #3391ff !important;
      color: var(--white);
    }
  }
  .tenth-color {
    &:hover {
      background: linear-gradient(90deg, #0cc862 0%, #38ef7d 100%);
      box-shadow: 0px 0px 26.34px 2.107px #38ef7d !important;
      color: var(--white);
    }
  }
  .inner-desktop {
    max-width: 1560px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    div {
      display: flex;
      column-gap: 37px;
      .btn1 {
        // font-size: 25.459px;
        font-size: 1.3vw;
        padding: 20px;
        width: 18.125vw;
        height: 102px;
        border-radius: 13.077px;
        box-shadow: 0px 0px 12.143px 0px rgba(0, 0, 0, 0.12);
      }
      .btn2 {
        padding: 20px;
        font-size: 18.682px;
        // font-size: 1vw;
        // width: 255.941px;
        width: 13.3vw;
        height: 73.793px;
        border-radius: 13.077px;
        box-shadow: 0px 0px 12.143px 0px rgba(0, 0, 0, 0.12);
      }
      .btn3 {
        font-size: 14.011px;
        width: 210.171px;
        height: 61.981px;
        border-radius: 7.888px;
        box-shadow: 0px 0px 7.325px 0px rgba(0, 0, 0, 0.12);
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: var(--black);
        font-family: "Montserrat";
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        font-style: normal;
        background: var(--white);
      }
    }
    .ist {
      align-items: flex-end;
      justify-content: center;
      button {
        &:first-child {
          margin-top: auto;
        }
      }
      button {
        &:nth-child(2) {
          margin-bottom: 13px;
        }
      }
      button {
        &:nth-child(3) {
          margin-bottom: 2.47px;
        }
      }
      button {
        &:nth-child(4) {
          margin-bottom: 18.68px;
        }
      }
      button {
        &:last-child {
          margin-bottom: 2.47px;
        }
      }
    }
    .second {
      justify-content: center;

      button {
        &:first-child {
          margin-top: 5.6px;
        }
      }

      button {
        &:nth-child(3) {
          margin-top: 5.6px;
        }
      }
      button {
        &:nth-child(4) {
          margin-top: 20px;
        }
      }
      button {
        &:last-child {
          margin-bottom: 5.6px;
        }
      }
    }
    .third {
      justify-content: center;

      button {
        &:first-child {
          margin-top: 24.28px;
        }
      }

      button {
        &:nth-child(3) {
          margin-top: 16.62px;
        }
      }
      button {
        &:nth-child(4) {
          margin-top: 16.81px;
        }
      }
      button {
        &:last-child {
          margin-top: 24.28px;
        }
      }
    }
    .forth {
      margin-top: -9px;
      justify-content: center;

      button {
        &:first-child {
          margin-top: 37.36px;
        }
      }

      button {
        &:nth-child(3) {
          margin-top: 28px;
        }
      }
      button {
        &:nth-child(4) {
          margin-top: 57.36px;
        }
      }
      button {
        &:last-child {
          margin-top: 37.36px;
        }
      }
    }
    .fifth {
      justify-content: flex-end;
      margin-top: -50px;
      margin-right: -36px;
      button {
        &:first-child {
          margin-top: 52px;
        }
      }

      button {
        &:nth-child(3) {
          margin-top: 52px;
        }
      }
      button {
        &:nth-child(4) {
          margin-top: 52px;
        }
      }
      button {
        &:last-child {
          margin-top: 58px;
        }
      }
    }
    .sixth {
      margin-top: -70px;
    }
  }
  .inner-mob {
    display: none;
  }
}
.modal-backdrop.show {
  opacity: 0 !important;
}
.button-modal {
  --bs-modal-width: unset !important;
  .btn-close {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .modal-content {
    width: 90vw;
    margin: 0px auto;
    background: rgba(255, 255, 255, 0.7);

    box-shadow: 0px 0px 7px 6px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(17px);
    border: none;
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    padding: 20px 60px 60px 60px;
    .wesay-modal {
      display: flex;
      column-gap: 80px;
      .left {
        border-right: 1px dashed #a1a1a1;
        padding-right: 90px;
      }
      .right {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
        position: relative;
        .demo-btn {
          position: absolute;
          right: 0px;
          bottom: 0px;

          &::after {
            display: none;
          }
        }
        .sub-head {
          color: var(--black-color) !important;
          line-height: 120% !important;
        }
        p.medium {
          color: var(--lightblack);
          line-height: 200%;
        }
        div {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1650px) {
  .wesay-sec {
    padding: 80px;
    .inner-desktop {
      row-gap: 20px;
      div {
        column-gap: 20px;
        .btn1 {
          font-size: 18px;
          padding: 0px;
          width: 270px;
          height: 80px;
        }
        .btn2 {
          padding: 0px;
          font-size: 15px;
          width: 200px;
          height: 60px;
        }
        .btn3 {
          font-size: 14px;
          width: 150px;
          height: 45px;
        }
      }

      .forth {
        margin-top: -20px;
        justify-content: center;

        button {
          &:first-child {
            margin-top: 25px;
          }
        }

        button {
          &:nth-child(3) {
            margin-top: 20px;
          }
        }
        button {
          &:nth-child(4) {
            margin-top: 35px;
          }
        }
        button {
          &:last-child {
            margin-top: 25px;
          }
        }
      }
      .fifth {
        justify-content: flex-end;
        margin-top: -30px;
        margin-right: -50px;
        button {
          &:first-child {
            margin-top: 32px;
          }
        }

        button {
          &:nth-child(3) {
            margin-top: 32px;
          }
        }
        button {
          &:nth-child(4) {
            margin-top: 32px;
          }
        }
        button {
          &:last-child {
            margin-top: 38px;
          }
        }
      }
      .sixth {
        margin-top: -50px;
      }
    }
  }
  .button-modal {
    .modal-body {
      padding: 20px 40px 40px 40px;
      .wesay-modal {
        display: flex;
        column-gap: 40px;
        .left {
          padding-right: 40px;
        }
        .right {
          row-gap: 30px;
          div {
            row-gap: 10px;
          }
          p.medium {
            line-height: 160%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .wesay-sec {
    padding: 80px 60px;
    .heading-img {
      width: 50px;
      height: 60px;
    }
    .inner-desktop {
      row-gap: 15px;
      div {
        column-gap: 15px;
        .btn1 {
          font-size: 16px;
          padding: 0px;
          width: 200px;
          height: 70px;
          border-radius: 10px;
        }
        .btn2 {
          padding: 0px;
          font-size: 14px;
          width: 180px;
          height: 55px;
          border-radius: 10px;
        }
        .btn3 {
          font-size: 12px;
          width: 130px;
          height: 45px;
          border-radius: 10px;
        }
      }
      .ist {
        align-items: flex-end;
        justify-content: center;
        button {
          &:first-child {
            margin-top: auto;
          }
        }
        button {
          &:nth-child(2) {
            margin-bottom: 7px;
          }
        }
        button {
          &:nth-child(3) {
            margin-bottom: 2.47px;
          }
        }
        button {
          &:nth-child(4) {
            margin-bottom: 13px;
          }
        }
        button {
          &:last-child {
            margin-bottom: 2.47px;
          }
        }
      }

      .third {
        justify-content: center;
        margin-top: -15px;
        button {
          &:first-child {
            margin-top: 15px;
          }
        }

        button {
          &:nth-child(3) {
            margin-top: 10px;
          }
        }
        button {
          &:nth-child(4) {
            margin-top: 10px;
          }
        }
        button {
          &:last-child {
            margin-top: 18px;
          }
        }
      }
      .fifth {
        justify-content: flex-end;
        margin-top: 0px;
        margin-right: 0px;
        button {
          &:first-child {
            margin-top: 15px;
          }
        }

        button {
          &:nth-child(3) {
            margin-top: 15px;
          }
        }
        button {
          &:nth-child(4) {
            margin-top: 15px;
          }
        }
        button {
          &:last-child {
            margin-top: 18px;
          }
        }
      }
      .sixth {
        margin-top: -30px;
      }
    }
  }
  .button-modal {
    .modal-body {
      padding: 20px 30px 30px 30px;
      .wesay-modal {
        display: flex;
        column-gap: 20px;
        .left {
          padding-right: 20px;
          img {
            width: 100%;
          }
        }
        .right {
          row-gap: 15px;
          width: 100%;
          div {
            row-gap: 10px;
          }
          p.medium {
            line-height: 140%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wesay-sec {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    .heading-img {
      width: 35px;
      height: 45px;
      object-fit: contain;
      margin: 0px auto;
      margin-bottom: 10px;
    }
    .inner-mob {
      padding: 0px 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;

      .btn1 {
        font-size: 13px;
        padding: 0px;
        width: 155px;
        height: 50px;
      }
      .btn2 {
        font-size: 13px;
        padding: 0px;
        width: 155px;
        height: 50px;
      }
      .btn3 {
        font-size: 13px;
        padding: 0px;
        width: 155px;
        height: 50px;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: var(--black);
        font-family: "Montserrat";
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        font-style: normal;
        background: var(--white);
        border-radius: 10px;
        box-shadow: 0px 0px 12.143px 0px rgba(0, 0, 0, 0.12);
      }
    }
    .inner-desktop {
      display: none;
    }
  }

  .button-modal {
    padding-left: 0px !important;
    .modal-content {
      width: 90vw;
      margin: 0px auto;
      h3 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
    .modal-body {
      padding: 15px;
      position: relative;
      .wesay-modal {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .left {
          border-right: unset;
          padding-right: 0px;
          img {
            width: 100%;
            object-fit: contain;
            height: 300px;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .sub-head {
            color: var(--black-color) !important;
            line-height: 120% !important;
          }
          p.medium {
            color: var(--lightblack);
            line-height: 130%;
            font-size: 15px;
          }
          div {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
          }
          .demo-btn {
            position: relative;
            margin-left: auto;
            width: 100px !important;
            font-size: 15px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
