.formWrapper {
  padding: 20px;
  max-width: 1332px;
  margin: 0 auto;
  filter: drop-shadow(0px 0px 38.6px rgba(255, 187, 0, 0.5));
  border-radius: 30px;
  background: #fff;
  margin-bottom: 260px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background-color: var(--main-color);
    top: 0;
    right: 0;
    width: 392px;
    height: 100%;
    border-radius: 0px 30px 30px 0px;
    opacity: 0.9;
    z-index: -1;
  }

  .form-left-content {
    width: 50%;
    // border: 1px solid purple;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .mainDiv {
      max-width: 410px;
      // border: 1px solid purple;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      grid-area: 20px;
    }
  }

  .form-right-content {
    width: 47%;
    height: auto;
    //border: 1px solid black;
    border-radius: 10px;
    background: var(--white, #fff);
    box-shadow: 0px 0px 19.8px 0px rgba(41, 41, 41, 0.25);
    padding: 50px;
    position: relative;

    form {
      // border: 1px solid green;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      .text-area {
        height: 140px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid var(--lightgray);
        background: #f9f9f9;
        padding: 20px;
        outline: none;
        &:focus {
          border: 1px solid #fb0;
          color: var(--black);
          background: var(--white);
        }
      }
      div {
        input {
          &:focus {
            border: 1px solid #fb0;
            color: var(--black);
            background: var(--white);
          }
          .validation-error {
            color: red !important;
          }
        }
      }
      button {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .formWrapper {
    max-width: 100%;
    width: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .formWrapper {
    padding: 40px;
  }
}
@media screen and (max-width: 600px) {
  .formWrapper {
    flex-direction: column;
    margin-top: 10px;
    gap: 40px;
    filter: none;
    padding: 10px;
    width: 95%;
    margin-bottom: 50px;
    &::before {
      display: none;
    }
    .form-left-content {
      width: 100%;
      align-items: center;
      justify-content: center;
      .mainDiv {
        h3 {
          text-align: center;
        }
      }
      p {
        text-align: center;
      }
    }
    .form-right-content {
      width: 100%;
      padding: 50px 20px;
      form {
        .name-fields {
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
  .aaa {
    margin-top: 130px;
  }
}
