.features-sec {
  padding: 45px 100px;
  display: flex;
  flex-direction: column;
  row-gap: 61px;
  position: relative;
  button {
    &::after {
      display: none;
    }
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 100px;
    max-width: 1672px;
    margin: 0px auto;
    position: relative;
    z-index: 4;
    .ist {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 70px;
      .left {
        max-width: 660px;
        position: relative;
        .back-img {
          // width: calc(100% - 19px);
          max-height: 523px;
        }
        .front-img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0px;
          margin: auto;
          height: 80%;
          animation: frontanimate 3s ease-in-out infinite;
        }
        @keyframes frontanimate {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(30px);
          }
          100% {
            transform: translateY(0);
          }
        }
      }
      .right {
        width: 50%;
        .right-content {
          max-width: 412px;
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          h5 {
            margin-bottom: 5px;
          }
          .para {
            margin-bottom: 10px;
          }
        }
      }
    }
    .second {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 70px;

      .right {
        max-width: 660px;
        // width: 50%;
        position: relative;
        .back-img {
          // width: calc(100% - 19px);
          max-height: 523px;
        }
        .front-img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: -20px;
          margin: auto auto auto 0px;
          height: 80%;
          animation: frontanimate 3s ease-in-out infinite;
        }
        @keyframes frontanimate {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(30px);
          }
          100% {
            transform: translateY(0);
          }
        }
      }

      .left {
        width: 50%;
        .left-content {
          max-width: 412px;
          display: flex;
          flex-direction: column;
          margin-left: auto;
          row-gap: 8px;
          h5 {
            margin-bottom: 5px;
          }
          .para {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .features-left {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 493px;
    height: 951px;
    object-fit: cover;
  }
  .features-right {
    position: absolute;
    z-index: 2;
    top: -500px;
    right: 0;
    width: 456px;
    height: 951px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1500px) {
  .features-sec {
    padding: 45px 80px;
    row-gap: 50px;
    .features-left {
      width: 300px;
      height: 620px;
      object-fit: cover;
    }
    .features-right {
      top: -200px;
      width: 250px;
      height: 500px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .features-sec {
    padding: 0px 80px;
    .inner {
      row-gap: 70px;
      .ist {
        column-gap: 50px;
        .left {
          .back-img {
            max-height: 300px;
          }
        }
      }
      .second {
        column-gap: 50px;
        .right {
          .back-img {
            max-height: 300px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .features-sec {
    padding: 0px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .back-img {
      width: 100%;
    }
    .inner {
      row-gap: 20px;
      padding: 0px 20px;
      .ist {
        width: 100%;
        flex-direction: column;
        row-gap: 10px;
        .left {
          width: 100%;
          max-width: 350px;
          margin: 0px auto;
          .front-img {
            left: 0;
            margin: unset;
            width: 100%;
          }
        }
        .right {
          width: 100%;
          .right-content {
            max-width: 412px;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 5px;
            h5 {
              margin-bottom: 0px;
            }
            .para {
              margin-bottom: 10px;
              text-align: center;
            }
          }
        }
      }
      .second {
        flex-direction: column-reverse;
        row-gap: 10px;
        .right {
          width: 100%;
          max-width: 350px;
          margin: 0px auto;
          .front-img {
            left: 0;
            margin: unset;
            width: 100%;
          }
        }
        .left {
          width: 100%;
          .left-content {
            row-gap: 5px;
            align-items: center;
            h5 {
              margin-bottom: 0px;
            }
            .para {
              margin-bottom: 10px;
              text-align: center;
            }
          }
        }
      }
    }
    .features-left {
      display: none;
    }
    .features-right {
      display: none;
    }
  }
}
