.summarizeContentWrapper {
  position: relative;
  padding: 10px 80px;
  // border: 1px solid red;
  .inner {
    max-width: 1636px;
    margin: 0 auto;
    .blackBorder {
      width: 100%;
      height: 100%;

      // border: 1px solid purple;
    }
  }

  // .content {

  //  // border: 2px solid red;
  // }
  // .whiteLayer {
  //   position: absolute;
  //   bottom: -20px;
  //   left: -0px;
  //  // max-width: 1764px;
  //   width: 100%;
  //   height: 419px;
  //   border: 1px solid purple;
  // }
}

@media screen and (max-width: 1200px) {
  .summarizeContentWrapper {
    padding-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .summarizeContentWrapper {
    padding: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
