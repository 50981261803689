.featureWrapper {
  padding: 20px;
  // border: 1px solid purple;
  margin-top: 60px;
  position: relative;
  width: 100%;
  z-index: 2;
  margin-bottom: 150px;

  .headingArea {
    h1 {
      position: absolute;
      font-size: 200.215px;
      z-index: -1;
      top: -110px;
      left: 21%;
      background: linear-gradient(
        180deg,
        rgba(255, 187, 0, 0.63) 19.67%,
        rgba(255, 187, 0, 0.36) 50.67%,
        rgba(255, 187, 0, 0) 89.14%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    h2 {
      position: relative;
      z-index: 3;
    }
  }

  .featureCardContainer {
    // width: 70%;
    max-width: 1150px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin: 0 auto;
    //  border: 2px solid green;
    position: relative;

    .yellowGlow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}
@media screen and (max-width: 1600px) {
  .featureWrapper .featureCardContainer .yellowGlow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: 100%;
  }
}

@media screen and (max-width: 600px){
  .featureWrapper{
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0;
    .featureCardContainer{
      margin-top: 0;
      gap: 20px;
    }
  }

}