@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
:root {
  --main-color: #fb0;
  --black: black;
  --white: white;
  --black-color: #2b2b2b;
  --lightgray: #828282;
  --lightblack: #676767;
  --lightblue: #000c60;
  --darkblue: #011334;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  max-width: 1920px;
  margin: 0px auto;
  overflow-x: hidden;
}
.h-center {
  display: flex;
  justify-content: center;
}
.text-inter {
  font-family: "Inter";
}
.text-jakarta {
  font-family: "Plus Jakarta Sans";
}
.text-montserrat {
  font-family: "Montserrat";
}
.text-roboto {
  font-family: "Roboto";
}
.text-opensans {
  font-family: "Open Sans";
}
.text-main {
  color: var(--main-color);
}
.text-black {
  color: var(--black);
}
.light-text {
  color: var(--lightblack);
}
.text-lightblack {
  color: var(--black-color);
}

h1 {
  font-size: 73px;
  line-height: normal;
  font-style: normal;
}
h2 {
  font-size: 64px;
  line-height: normal;
  font-style: normal;
}
h3 {
  font-size: 50px;
  line-height: normal;
  font-style: normal;
}
h4 {
  font-size: 44px;
  line-height: normal;
  font-style: normal;
}
h5 {
  font-size: 38px;
  line-height: normal;
  font-style: normal;
}
h6 {
  font-size: 25px;
  line-height: normal;
  font-style: normal;
}

.extra-large {
  font-size: 35px;
  font-style: normal;
  line-height: normal;
}
.large {
  font-size: 22px;
  font-style: normal;
  line-height: normal;
}

.medium {
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}
.small {
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}
label,
p {
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  color: var(--lightblack);
}
button {
  cursor: pointer !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
.mt-10 {
  margin-top: 170px;
}
.input-fields {
  width: 100%;
  padding: 13px 20px;
  outline: none;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 26px;
  color: var(--lightgray);
  border-radius: 10px;
  border: 1px solid var(--lightgray);
  background: #f9f9f9;
  color: var(--darkblue);

  &::placeholder {
    color: var(--lightgray);
  }
}
.modal-dialog{
  max-width: unset !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.top-sec-padding {
  padding-top: 200px;
}
@media screen and (max-width: 1750px) {
  h1 {
    font-size: 60px;
    line-height: 110%;
  }
}

@media screen and (max-width: 1500px) {
  h2 {
    font-size: 50px;
  }
  h3 {
    font-size: 42px;
    line-height: normal;
    font-style: normal;
  }
  h4 {
    font-size: 36px;
    line-height: normal;
    font-style: normal;
  }
  h5 {
    font-size: 28px;
    line-height: normal;
    font-style: normal;
  }
  h6 {
    font-size: 22px;
    line-height: normal;
    font-style: normal;
  }
  .benefit-headings {
    font-size: 22px;
    line-height: normal;
    font-style: normal;
  }
  .extra-large {
    font-size: 23px;
    font-style: normal;
    line-height: normal;
  }
  .large {
    font-size: 20px;
    font-style: normal;
    line-height: normal;
  }
  .medium {
    font-size: 19px;
  }
  .small {
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }
  label,
  p {
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    color: var(--lightblack);
  }
  @media screen and (max-width: 1280px) {
    h1 {
      font-size: 42px;
    }
    h2 {
      font-size: 40px;
    }
    h3 {
      font-size: 32px;
    }
    h5 {
      font-size: 24px;
      line-height: normal;
      font-style: normal;
    }
    h6 {
      font-size: 20px;
    }
    .benefit-headings {
      font-size: 20px;
    }
    .extra-large {
      font-size: 16px;
    }
    .large {
      font-size: 16px;
    }
    p,
    label {
      font-size: 16px;
    }
    .medium {
      font-size: 16px;
    }
  }
  .top-sec-padding {
    padding-top: 150px;
  }
}
@media screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
  .benefit-headings {
    font-size: 18px;
  }
  .extra-large {
    font-size: 16px;
  }
  .large {
    font-size: 16px;
  }
  .medium {
    font-size: 16px;
  }

  label,
  p {
    font-size: 16px;
  }
  .top-sec-padding {
    padding-top: 100px;
  }
}
