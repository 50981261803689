.faqs-sec {
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  position: relative;
  overflow-x: hidden;
  .inner {
    max-width: 1582px;
    margin: 0px auto;
    padding: 0px 100px;
    position: relative;
    z-index: 5;
    .faqs-accordion {
      .accordion {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        align-items: flex-start;
        gap: 27px;
      }
      .accordion-item {
        border-radius: 16.033px;
        background: var(--white);
      }

      .accordion-button {
        padding: 0px;
        border-radius: 16.033px;
        color: var(--black);
        font-family: "Montserrat";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 127.273%; /* 127.273% */
        column-gap: 10px;
      }

      .accordion-button:focus {
        border: none;
        box-shadow: none;
      }
      .accordion-item {
        border: none;
        padding: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 9px;
      }
      .accordion-button:not(.collapsed) {
        color: unset;
        background-color: var(--white);
        box-shadow: unset;
      }
      .accordion-button:not(.collapsed)::after {
        background-image: url(/assets/icons/open.svg);

        transform: var(--bs-accordion-btn-icon-transform);
      }
      .accordion-button::after {
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        margin-left: auto;

        content: "";
        background-image: url(/assets/icons/close.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 41px;
        height: 41px;
        background-position: center;
        transition: var(--bs-accordion-btn-icon-transition);
      }
      .accordion-body {
        padding: 0px;
        color: var(--lightblack);
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 166%; /* 166.667% */
      }
    }
  }
  .left-back {
    position: absolute;
    top: 0;
    left: -280px;
    border-radius: 560px;
    opacity: 0.2;
    background: #00ffc2;

    filter: blur(200.35000610351562px);
    width: 560px;
    height: 560px;
    z-index: 3;
  }
  .right-back {
    position: absolute;
    bottom: 0;
    right: -280px;
    border-radius: 560px;
    opacity: 0.2;
    background: #fb0;

    filter: blur(200.35000610351562px);
    width: 560px;
    height: 560px;
    z-index: 3;
  }
}

@media screen and (max-width: 1500px) {
  .faqs-sec {
    padding: 0px 0px 80px 0px;

    row-gap: 60px;
    .inner {
      padding: 0px 80px;
      .faqs-accordion {
        .accordion {
          gap: 20px;
        }

        .accordion-button {
          font-size: 20px;
        }

        .accordion-button::after {
          width: 35px;
          height: 35px;
        }
        .accordion-body {
          line-height: 140%; /* 166.667% */
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .faqs-sec {
    padding: 0px 0px 80px 0px;

    row-gap: 30px;
    .inner {
      padding: 0px 80px;
      .faqs-accordion {
        .accordion {
          gap: 20px;
        }

        .accordion-button {
          font-size: 20px;
        }

        .accordion-button::after {
          width: 35px;
          height: 35px;
        }
        .accordion-body {
          line-height: 140%; /* 166.667% */
        }
      }
    }

    .left-back {
      left: -200px;
      width: 400px;
      height: 400px;
    }
    .right-back {
      right: -200px;
      width: 400px;
      height: 400px;
    }
  }
}

@media screen and (max-width: 600px) {
  .faqs-sec {
    padding: 30px 0px;
    row-gap: 20px;
    .inner {
      padding: 0px 20px;
      .faqs-accordion {
        .accordion {
          grid-template-columns: repeat(1, 1fr);

          gap: 20px;
        }
        .accordion-item {
          border-radius: 10px;
        }

        .accordion-button {
          border-radius: 10px;
          font-size: 18px;
          line-height: 120%; /* 127.273% */
          column-gap: 10px;
        }

        .accordion-item {
          padding: 20px;
          row-gap: 5px;
        }
        .accordion-button::after {
          width: 30px;
          height: 30px;
        }
        .accordion-body {
          font-size: 16px;
          line-height: 130%; /* 166.667% */
        }
      }
    }
    .left-back {
      display: none;
    }
    .right-back {
      display: none;
    }
  }
}
