.aiImages-sec {
  margin-top: 60px;
  .inner {
    padding: 0px 80px;
    .images-divs {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 33px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
      .first {
        row-gap: 25px;
        display: grid;
        grid-template-rows: 18.2vw 18.2vw;
      }
      .second {
        row-gap: 25px;
        display: grid;
        grid-template-rows: 22.7vw 13.7vw;
      }
      .third {
        row-gap: 25px;
        display: grid;
        grid-template-rows: 19.6vw 16.8vw;
      }
      .forth {
        row-gap: 25px;
        display: grid;
        grid-template-rows: 25.3vw 11.1vw;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .aiImages-sec {
    .inner {
      .images-divs {
        gap: 20px;
        .first {
          row-gap: 15px;
        }
        .second {
          row-gap: 15px;
        }
        .third {
          row-gap: 15px;
        }
        .forth {
          row-gap: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .aiImages-sec {
    .inner {
      padding: 0px 20px;
      .images-divs {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
        img {
          max-width: 350px;
          margin: 0px auto;
        }
        .first,
        .second,
        .third,
        .forth {
          row-gap: 15px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
