.footer-sec {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .footer-top {
    background: url(/assets/images/footerback.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0px 200px;
    position: relative;
    .inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 120px;
      max-width: 1270px;
      margin: 0px auto;
      .right {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        max-width: 631px;
        .content {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
        }
        .input-div {
          display: flex;
          align-items: center;
          width: 100%;
          background: var(--white);
          padding: 3px;
          button {
            &::after {
              display: none;
            }
          }
          input {
            padding: 0px 32px;
            width: 100%;
            border: none;
            outline: none;
            color: #2b2b2b;
            opacity: 0.5;
            font-family: Inter;
            font-size: 22.046px;
            font-style: normal;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }
    }
    .minion-img {
      position: absolute;
      bottom: 12%;
      right: -20%;
      width: 300px;
      height: 300px;
      object-fit: cover;
      z-index: 1;
      transition: all 0.8s ease-in-out;
    }
    .footerback-left {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
    }
    .footerback-right {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
  .footer-bottom {
    background: var(--black);
    padding: 82px 100px 33px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 13px;
    .social-icons {
      display: flex;
      align-items: center;
      column-gap: 22px;
      a {
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }
    .privacy-div {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 16px;
      margin-top: 10px;
      margin-bottom: 5px;
      a {
        color: rgba(255, 255, 255, 0.56);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }
    }
    p {
      color: rgba(255, 255, 255, 0.56);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
  }
  &:hover {
    .footer-top {
      .minion-img {
        right: -2%;
        transform: rotate(-30deg);
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  .footer-sec {
    .footer-top {
      padding: 0px;
      .inner {
        max-width: 1100px;
        justify-content: center;
        column-gap: 40px;
        .left {
          width: 300px;
          height: 400px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .right {
          row-gap: 10px;
          max-width: 500px;
          .input-div {
            input {
              padding: 0px 25px;
              font-size: 20px;
            }
          }
        }
      }
      .minion-img {
        width: 200px;
        height: 200px;
      }
      .footerback-left {
        width: 200px;
      }
      .footerback-right {
        width: 200px;
      }
    }
    .footer-bottom {
      padding: 50px 100px 33px 100px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .footer-sec {
    .footer-top {
      .inner {
        column-gap: 30px;
        .left {
          width: 250px;
          height: 400px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .right {
          row-gap: 10px;
          max-width: 350px;
          button {
            font-size: 18px;
            width: 150px !important;
            height: 40px !important;
          }
          .input-div {
            input {
              padding: 0px 20px;
              font-size: 18px;
            }
          }
        }
      }
      .minion-img {
        width: 200px;
        height: 200px;
      }
      .footerback-left {
        width: 200px;
      }
      .footerback-right {
        width: 200px;
      }
    }
    .footer-bottom {
      padding: 50px 100px 33px 100px;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-sec {
    .footer-top {
      padding: 30px 20px 0px 20px;
      background: var(--black);
      .inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 10px;

        .left {
          display: none;
        }
        .right {
          row-gap: 10px;

          .content {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            h1 {
              text-align: center;
              color: var(--white);
            }
            p {
              color: var(--white) !important;
              text-align: center;
            }
          }
          .input-div {
            padding: 2px;
            max-width: 400px;
            button {
              font-size: 16px;
              height: 35px !important;
            }
            input {
              padding: 0px 15px;
              font-size: 16px;
            }
          }
        }
      }
      .minion-img {
        display: none;
      }
      .footerback-left {
        display: none;
      }
      .footerback-right {
        display: none;
      }
    }
    .footer-bottom {
      padding: 20px 20px 30px 20px;
      row-gap: 10px;
      .social-icons {
        column-gap: 15px;
        a {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .privacy-div {
        column-gap: 15px;
        a {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
