.iqLevelWrapper{
    padding: 20px;
    //border: 1px solid red;
    .inner{
        width: 80%;
        margin: 0 auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
@media screen and (max-width:600px){
    .iqLevelWrapper{
        .inner{
            width: 100%;
        }
    }
    
}