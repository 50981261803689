.featureCard {
  max-width: 550.19px;
  height: 265.76px;
  border-radius: 19px;
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 17px;
  padding: 40px 60px;
  box-shadow: 0px 4px 44px 0px rgba(76, 87, 186, 0.07);
  // border: 1px solid blue;
  transition: all 1s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  h5 {
    color: var(--lightblue);
  }
  p {
    color: #64698e;
    font-style: normal;
    line-height: 21px;
  }
}
@media screen and (max-width: 1200px) {
  .featureCard {
    max-width: 450px;
    padding: 40px;
    box-shadow: 0 23.211px 80.27px 0 #0000001a, 0 4.836px 17.408px 0 #0000000f,
      0 1.934px 5.803px 0 #0000000a;
  }
}
@media screen and (max-width: 600px) {
  .featureCard {
    padding: 20px;
    height: auto;
    max-width: 100%;
    transition: none;
    &:hover {
      transform: none;
    }
  }
}
