.assistentDiv {
  padding: 20px;
 // border: 2px solid green;
 padding-bottom: 70px;
  .headingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 14px;
  }
  .inner{
    width: 85%;
    margin: 0 auto;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
}
