.roadmap-sec {
  .service-slider_wrapper {
    overflow: hidden;
    .service-slider__item {
      position: relative;
      img {
      }
      .features {
        display: flex;
        flex-direction: column;
        row-gap: 1px;
      }
      .quarter1 {
        position: absolute;
        left: 4.8%;
        z-index: 5;
        bottom: 8%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
      .quarter2 {
        position: absolute;
        left: 22.4%;
        z-index: 5;
        top: 23%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
      .quarter3 {
        position: absolute;
        left: 38%;
        z-index: 5;
        bottom: 20%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
      .quarter4 {
        position: absolute;
        left: 55.3%;
        z-index: 5;
        top: 17%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
      .quarter5 {
        position: absolute;
        left: 71.8%;
        z-index: 5;
        bottom: 11%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
      .quarter6 {
        position: absolute;
        left: 89.4%;
        z-index: 5;
        top: 22%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
    }
  }
}
.mob-roadmap {
  display: none;
}

@media screen and (max-width: 600px) {
  .roadmap-sec {
    display: none;
  }
  .mob-roadmap {
    display: block;
    padding: 30px 0px 0px 0px;
    overflow-x: hidden;
    img {
      width: 100%;
    }
  }
}
