.heading-sec {
  position: relative;
  z-index: 3;
  h1 {
    text-align: center;
    font-family: "Montserrat";
    font-size: 200.215px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background: linear-gradient(
      180deg,
      rgba(255, 187, 0, 0.63) 19.67%,
      rgba(255, 187, 0, 0.36) 50.67%,
      rgba(255, 187, 0, 0) 89.14%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h2 {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%);
  }
}

@media screen and (max-width: 1500px) {
  .heading-sec {
    h1 {
      font-size: 150px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .heading-sec {
    h1 {
      font-size: 120px;
    }
  }
}
@media screen and (max-width: 600px) {
  .heading-sec {
    h1 {
      font-size: 40px;
    }
    h2 {
      bottom: 0px;
      width: 100%;
      text-align: center;
    }
  }
}
