.chooseus-sec {
  background: var(--black);
  padding: 40px 100px 147px 100px;
  position: relative;
  box-shadow: 0px 4px 4px 0px #fb0;
  z-index: 2;
  .inner {
    max-width: 1466px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    row-gap: 22px;
    .head {
      padding-right: 100px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .title-div {
        display: flex;
        flex-direction: column;
        row-gap: 21px;
        .title {
          padding: 16px 52px;
          background: var(--main-color);
          width: fit-content;
          color: var(--black-color);
          border-radius: 62px;
        }
      }
      .arrow-div {
        width: 75px;
        height: 75px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--main-color);
        transform: rotate(45deg);
        transition: all 0.4s linear;
        cursor: pointer;
        svg {
          transition: all 0.4s linear;
          width: 33px;
          height: 33px;
          color: var(--main-color);
        }
        &:hover {
          border: none;
          background: var(--main-color);
          svg {
            transform: rotate(47deg);
            color: var(--black);
          }
        }
      }
    }
    .detail {
      display: flex;
      align-items: center;
      column-gap: 28px;
      .left {
        width: 50%;
        padding: 2px;
        position: relative;
        overflow: hidden;
        background: var(--black);
        border-radius: 53px;
        max-width: 728px;
        max-height: 816px;
        .img-div {
          padding: 1px;
          width: 100%;
          height: 100%;
          background: var(--black);
          padding: 26px;
          position: relative;
          z-index: 3;
          border-radius: 53px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 53px;

            transition: all 0.4s linear;
          }
        }

        .back-layer {
          position: absolute;
          top: -177px;
          left: 0px;
          bottom: 0px;
          right: -214px;

          width: 510.488px;
          height: 1176px;
          z-index: 2;
          transform: rotate(38.553deg);

          //   animation: rotate 3s linear infinite;
        }
        @keyframes rotate {
          0% {
            transform: rotate(38.553deg);
          }
          50% {
            transform: rotate(120deg);
          }
          100% {
            transform: rotate(38.553deg);
          }
        }
      }
      .right {
        width: 50%;
        padding-right: 30px;
        position: relative;
        border-right: 3px solid var(--main-color);
        .ist {
          display: flex;
          align-items: center;
          column-gap: 28px;
          .content {
            padding: 48px 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            row-gap: 16px;
            position: relative;
            &::after {
              transition: all 0.4s linear;

              content: "";
              position: absolute;
              top: 0;
              width: 80%;
              height: 3px;
              background: var(--main-color);
            }
          }

          .number {
            transform: rotate(90deg);
            transition: all 0.4s linear;
          }
          h5 {
            color: var(--main-color);
            transition: all 0.4s linear;
          }
          p {
            color: var(--white);
            line-height: 170%;
            text-align: right;
          }
          &:hover {
            h5 {
              color: var(--white);
            }
            .number {
              transform: unset;
            }
            .content {
              &::after {
                background: var(--white);
              }
            }
          }
        }
        .ist:last-child {
          .content {
            padding-bottom: 0px;
          }
        }
        &::after {
          position: absolute;
          content: "";
          background: url(/assets/images/chooseusright.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          top: 2px;
          right: -7px;
          width: 10px;
          height: 56px;

          animation: shower 4s linear infinite;
        }
        @keyframes shower {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(750px);
          }
          100% {
            transform: translateY(0);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .chooseus-sec {
    padding: 40px 80px 100px 80px;
    .inner {
      row-gap: 20px;
      .head {
        padding-right: 80px;
        .title-div {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .title {
            padding: 14px 40px;
          }
        }
        .arrow-div {
          width: 60px;
          height: 60px;

          svg {
            width: 26px;
          }
        }
      }
      .detail {
        column-gap: 20px;
        .left {
          width: 50%;
          padding: 2px;
          position: relative;
          overflow: hidden;
          background: var(--black);
          border-radius: 53px;
          max-width: 728px;
          max-height: 816px;
          .img-div {
            padding: 1px;
            width: 100%;
            height: 100%;
            background: var(--black);
            padding: 26px;
            position: relative;
            z-index: 3;
            border-radius: 53px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 53px;

              transition: all 0.4s linear;
            }
          }

          .back-layer {
            position: absolute;
            top: -177px;
            left: 0px;
            bottom: 0px;
            right: -214px;

            width: 510.488px;
            height: 1176px;
            z-index: 2;
            transform: rotate(38.553deg);

            //   animation: rotate 3s linear infinite;
          }
          @keyframes rotate {
            0% {
              transform: rotate(38.553deg);
            }
            50% {
              transform: rotate(120deg);
            }
            100% {
              transform: rotate(38.553deg);
            }
          }
        }
        .right {
          width: 50%;
          padding-right: 15px;
          @keyframes shower {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(550px);
            }
            100% {
              transform: translateY(0);
            }
          }
          .ist {
            column-gap: 15px;
            .content {
              padding: 30px 0px;
              row-gap: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .chooseus-sec {
    padding: 40px 80px 80px 80px;
    .inner {
      row-gap: 15px;
      .head {
        padding-right: 90px;
      }
      .detail {
        column-gap: 20px;
        .left {
          .img-div {
            padding: 20px;
          }
        }
        .right {
          width: 50%;
          padding-right: 10px;
          position: relative;
          border-right: 3px solid var(--main-color);
          @keyframes shower {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(500px);
            }
            100% {
              transform: translateY(0);
            }
          }
          .ist {
            display: flex;
            align-items: center;
            column-gap: 10px;
            .content {
              padding: 20px 0px;
              row-gap: 7px;
            }
            p {
              line-height: 140%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .chooseus-sec {
    padding: 50px 20px;
    .inner {
      row-gap: 20px;
      .head {
        padding-right: 0px;
        .title-div {
          row-gap: 10px;
          .title {
            padding: 12px 30px;
          }
        }
        .arrow-div {
          width: 50px;
          height: 50px;

          svg {
            width: 22px;
          }
        }
      }
      .detail {
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;
        .left {
          width: 100%;
          border-radius: 20px;
          max-width: 350px;
          .img-div {
            padding: 15px;
            border-radius: 20px;
            img {
              border-radius: 20px;
            }
          }
        }
        .right {
          width: 100%;
          padding-right: 0px;
          border: none;
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          &::after {
            display: none;
          }
          .ist {
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
            justify-content: center;
            row-gap: 10px;
            .number {
              transform: rotate(0deg);
            }
            .content {
              padding: 0px 0px;
              row-gap: 10px;
              h5 {
                text-align: center;
                margin: 0px auto;
                transition: unset;
              }
              &::after {
                display: none;
              }
            }

            p {
              line-height: 140%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
