.statistics-sec {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  row-gap: 66px;
  .inner {
    max-width: 1572px;
    margin: 0px auto;
    .stat-cards {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 68px 100px;
      .stat-card {
        width: 400px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 22.031px;
        background: #fff;
        box-shadow: 0px 0px 17.135px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        img {
          width: 90px;
          height: 90px;
          object-fit: contain;
          position: relative;
          z-index: 2;
        }
        h6 {
          transition: all 0.5s linear;
          position: relative;
          z-index: 2;
          width: fit-content;
        }
        p {
          transition: all 0.5s linear;

          position: relative;
          z-index: 2;
        }
        .card-circle {
          transition: all 0.5s linear;
          position: absolute;
          top: 65px;
          left: 50%;
          transform: translate(-50%);
          background: var(--main-color);
          width: 140px;
          height: 140px;
          border-radius: 100%;
        }
        &:hover {
          .card-circle {
            transform: scale(4);
            border-radius: 0;
          }
          h6 {
            color: var(--white) !important;
          }
          p {
            color: var(--white) !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .statistics-sec {
    padding: 80px;
    row-gap: 50px;
    .inner {
      .stat-cards {
        gap: 50px 60px;
        .stat-card {
          width: 330px;
          height: 330px;
          img {
            width: 70px;
            height: 70px;
          }

          .card-circle {
            top: 60px;
            width: 100px;
            height: 100px;
          }
          &:hover {
            .card-circle {
              transform: scale(5);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .statistics-sec {
    padding: 50px 80px;
    row-gap: 40px;
    .inner {
      .stat-cards {
        gap: 30px 40px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .statistics-sec {
    padding: 30px 20px;
    row-gap: 20px;
    .inner {
      .stat-cards {
        gap: 20px;
        .stat-card {
          width: 250px;
          height: 250px;
          img {
            width: 70px;
            height: 70px;
          }

          .card-circle {
            top: 35px;
            width: 100px;
            height: 100px;
          }
          &:hover {
            .card-circle {
              transform: scale(5);
            }
          }
        }
      }
    }
  }
}
