.benefitWrapper {
  padding: 20px;
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  //border: 2px solid blue;
  .backLayer {
    position: absolute;
    top: 34%;
    // width: 100%;
    left: 0;
  }

  .centerDiv {
    width: 80%;
    margin: 0 auto;
    border-radius: 74px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 117px 0px rgba(76, 87, 186, 0.25);
    backdrop-filter: blur(15px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 135px !important;
    // border: 2px solid green;
  }
}

@media screen and (max-width: 1450px) {
  .benefitWrapper {
    .centerDiv {
      margin-top: 56px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .benefitWrapper {
    padding-bottom: 50px;
    .backLayer {
      display: none;
    }
    .centerDiv {
      width: 100%;
      grid-template-columns: repeat(1, 1fr);
      border-radius: 20px;
      margin-top: 10px !important;
      box-shadow: unset;
    }
  }
}
