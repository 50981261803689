.navbar-sec {
  max-width: 1920px;
  margin: 0px auto;
  position: absolute;
  top: 34px;
  z-index: 8;
  margin: 0px auto;
  width: 100%;
  padding: 0px 100px;

  .activeimg {
    transition: all 0.3s linear;
    transform: rotate(180deg);
  }
  .inner {
    max-width: 1638px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border: 1px solid purple;
    .left {
      width: 117px;
      height: 77.447px;
      //border: 1px solid red;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .center {
      //margin-left: 120px;
      //  border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 60px;
      border-radius: 158px;
      border: 1px solid var(--main-color);
      background: #fff;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
      padding: 17px 76px;
      position: relative;
      a {
        img {
          transition: all 0.3s linear;
        }
      }
      .solutions-div {
        position: absolute;
        z-index: 20;
        left: 70px;
        top: 80px;
        display: flex;
        column-gap: 23px;
        border-radius: 9px;
        background: rgba(25, 1, 69, 0.1);
        backdrop-filter: blur(46.43333435058594px);
        padding: 32px 44px;
        .tab-side {
          padding: 13px 35px 56px 35px;
          border-radius: 10px;
          background: #fb0;
          display: flex;
          flex-direction: column;
          width: 248px;
          row-gap: 11px;
          label {
            color: var(--white);
            font-family: "Open Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%; /* 28.8px */
            margin-bottom: 10px;
          }
          .tab-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            background: #fff;
            width: 178px;
            padding: 10px;
            cursor: pointer;

            transition: 0.5s ease-in;

            &:hover {
              transition: all 1s ease-in-out;
            }

            span {
              color: #2b2b2b;
              font-family: "Open Sans";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 160%;
            }
          }
        }
        .content-side {
          padding: 13px 21px;
          background: var(--white);
          border-radius: 10px;
          width: 391px;
          display: flex;
          flex-direction: column;
          row-gap: 29px;
          transition: all 1s ease-in-out;
          //border: 1px solid firebrick;

          .head {
            position: relative;
            width: fit-content;
            font-size: 14px;
            line-height: 160%; /* 22.4px */
            text-transform: capitalize;
            &::after {
              content: "";
              position: absolute;
              left: 50%;
              transform: translate(-50%);
              bottom: -2px;
              width: 77px;
              height: 1px;
              background: linear-gradient(
                90deg,
                rgba(255, 187, 0, 0) 0%,
                #fb0 52.69%,
                rgba(255, 187, 0, 0) 100%
              );
            }
          }
          .content {
            font-size: 12px;
            line-height: 171%;
            text-transform: capitalize;
          }
        }
      }
      a {
        color: var(--black);
        cursor: pointer;
        font-size: 18px;
      }
      a.active {
        position: relative;
        &::after {
          position: absolute;
          bottom: -10px;
          content: "";
          width: 100%;
          left: 0;
          right: 0;
          height: 3px;
          border-radius: 8px;
          background: var(--main-color);
        }
      }
      .not-solution {
        display: none;
      }
    }
    .right {
      display: flex;
      align-items: center;
      column-gap: 26px;
      //border: 1px solid green;
    }
  }
}
.mobile-nav {
  display: none;
}

@media screen and (max-width: 1670px) {
  .navbar-sec {
    padding: 0 30px;
  }
}
@media screen and (max-width: 1550px) {
  .navbar-sec {
    // padding: 0px 80px;
    .large-btn {
      width: 150px !important;
      height: 50px !important;
      font-size: 20px;
    }
    .inner {
      .left {
        width: 100px;
        height: 70px;
      }
      .center {
        // margin-left: 70px;
        column-gap: 50px;
        padding: 17px 50px;
        .solutions-div {
          top: 80px;
          column-gap: 15px;
          padding: 20px 20px;
          .tab-side {
            padding: 13px 20px 56px 20px;
            border-radius: 10px;
            background: #fb0;
            display: flex;
            flex-direction: column;
            width: 220px;
            row-gap: 11px;
            label {
              font-size: 17px;

              margin-bottom: 5px;
            }
            .tab-item {
              width: 100%;
            }
          }
          .content-side {
            padding: 13px 20px;
            width: 350px;
            row-gap: 20px;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        column-gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .navbar-sec {
    top: 30px;
    .inner {
      .center {
        //  margin-left: 80px;
        column-gap: 30px;
        padding: 17px 40px;
        .solutions-div {
          left: 30px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        column-gap: 15px;
        button {
          width: 100px !important;
          font-size: 16px;
          height: 45px !important;
        }
      }
    }
  }
  .navbar-sec .inner .center a {
    color: var(--black);
    cursor: pointer;
    font-size: 16px;
  }
}
@media screen and (max-width: 1100px) {
  .navbar-sec {
    top: 30px;
    padding: 0px 20px;
    .inner {
      .left {
        display: flex;
        justify-content: center;
        img {
          width: 80px;
        }
      }
      .center {
        //margin-left: 40px;
        padding: 17px 30px;
        .solutions-div {
          left: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    background: var(--white);
    .topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      .mobile-logo {
        width: 80px;
      }
    }
    .sidebar {
      display: none;
    }
    .menus {
      transition: all 0.5s linear;
      position: absolute;
      z-index: 8;
      background: var(--white);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 10px;
      padding: 0px 30px 30px 30px;
      box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 20px 20px;
      .first {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
        a {
          color: var(--black-color);
          cursor: pointer;
        }
        a.active {
          position: relative;
          width: fit-content;
          &::after {
            position: absolute;
            bottom: -2px;
            content: "";
            width: 100%;
            left: 0;
            right: 0;
            height: 3px;
            border-radius: 8px;
            background: var(--main-color);
          }
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
        button {
          font-size: 16px;
        }
      }
    }
    .offcanvas {
      position: relative;
      z-index: 3;
      .offcanvas-body {
        .tab-side {
          display: flex !important;
          flex-direction: column !important;
        }
      }
    }
  }
  .solution-offcanvas {
    .offcanvas-body {
      .tab-side {
        border-radius: 9px;
        background: rgba(25, 1, 69, 0.1);
        backdrop-filter: blur(46.43333435058594px);
        padding: 32px 44px;
        .inner {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          border-radius: 10px;
          background: #fb0;
          padding: 13px 26px;
          label {
            color: var(--white);
            font-family: "Open Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%; /* 28.8px */
            margin-bottom: 10px;
          }
          .tab-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            background: #fff;
            padding: 10px;
            cursor: pointer;

            span {
              color: #2b2b2b;
              font-family: "Open Sans";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 160%;
            }
          }
        }
        a {
          padding: 10px;
        }
      }
    }
  }
}
