.term-wrapper {
  position: relative;
  margin-top: 200px;
  padding-bottom: 100px;
  .textArea {
    width: 77%;
    // max-width: 1400px;
    margin: 0 auto;
    // border: 1px solid green;

    h6 {
      color: #2b2b2b;
    }
    p {
      color: #676767;
      line-height: normal;
    }
  }
}
@media screen and (max-width: 1500px) {
  .term-wrapper {
    margin-top: 100px;
    padding-bottom: 60px;
    .textArea {
      width: 90%;
      p.para {
        margin-top: 8px !important;
      }
      h6 {
        margin-top: 10px !important;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .term-wrapper {
    padding-bottom: 30px;
    margin-top: 80px;
    .textArea {
      p.para {
        margin: 5px 0px !important;
      }
    }
  }
}
