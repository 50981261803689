.pricing-sec {
  padding: 163px 0px 300px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  position: relative;
  .inner {
    padding: 0px 80px;
    position: relative;
    z-index: 5;

    button {
      font-size: 13.7px;
      margin-top: auto;
    }
    .pricing-cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      max-width: 1545px;
      margin: 0px auto;

      column-gap: 31px;
      width: 100%;
      .ist {
        padding: 54px 45px 46px 45px;
        border-radius: 36.522px;

        background: var(--white);
        box-shadow: 0px 1.66px 4.98px 0px rgba(0, 0, 0, 0.04),
          0px 4.15px 14.941px 0px rgba(0, 0, 0, 0.06),
          0px 19.921px 68.895px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        row-gap: 22px;
        span {
          font-size: 14px;
          line-height: 142%;
        }
        .top {
          display: flex;
          flex-direction: column;
          border-bottom: 1.795px solid #d1d1f7;
          padding-bottom: 15px;
          row-gap: 5.57px;
        }
        .center {
          display: flex;
          flex-direction: column;
          row-gap: 22px;
          margin-bottom: 54px;
          h6 {
            letter-spacing: -1.178px;
            span {
              font-size: 10.954px;
              letter-spacing: 0.5px;
              line-height: 145%;
            }
          }
          .features {
            display: flex;
            flex-direction: column;
            row-gap: 4.29px;
            .feature {
              display: flex;
              flex-direction: column;
              row-gap: 8px;
            }
          }
          ul {
            padding-left: 46px;
            margin: 0px;
            li {
              color: var(--light-black);
              font-family: "Montserrat";
              font-size: 14.012px;
              font-style: normal;
              font-weight: 400;
              line-height: 147%; /* 171.4% */
              letter-spacing: 0.074px;
            }
          }
        }
      }
    }
  }
  .back-div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    div {
      width: 560px;
      height: 1023px;
      transform: rotate(90deg);

      position: relative;
      z-index: 3;
      border-radius: 1023px;
      opacity: 0.2;
      background: #ff3d00;
      filter: blur(233.26466369628906px);
    }
  }
}

@media screen and (max-width: 1500px) {
  .pricing-sec {
    padding: 163px 0px 150px 0px;
    row-gap: 40px;
    .inner {
      padding: 0px 60px;
      .pricing-cards {
        column-gap: 20px;
        .ist {
          padding: 20px 20px 20px 20px;
          border-radius: 25px;
          row-gap: 15px;
          .center {
            row-gap: 15px;
            margin-bottom: 30px;
            .features {
              display: flex;
              flex-direction: column;
              row-gap: 4.29px;
              .feature {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .pricing-sec {
    padding: 140px 0px 100px 0px;
    .inner {
      padding: 0px 80px;
      .pricing-cards {
        gap: 30px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .ist {
          width: 350px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .pricing-sec {
    padding: 100px 0px 30px 0px;
    row-gap: 30px;
    .inner {
      padding: 0px 20px;

      .pricing-cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
        width: 100%;
        .ist {
          max-width: 350px;
          margin: 0px auto;
          width: 100%;
          padding: 30px;
        }
      }
    }
    .back-div {
      display: none;
    }
  }
}
